:root {
    --neutral-variant95: #ecf1f9;
    --bahama-blue: #fff;
    --bahama-blue-2: #fff;
    --material-themeblack: #000000;
    --material-themekey-colorsprimary: #1da1f2;
    --blue-app-color: #207ACF;
    --material-themekey-colorstertiary: #50dcaa;
    --material-themereferrorerror0: #000000;
    --material-themereferrorerror100: #ffffff;
    --material-themerefneutral-variantneutral-variant0: #000000;
    --material-themerefneutral-variantneutral-variant100: #ffffff;
    --material-themerefneutral-variantneutral-variant90: #dee3eb;
    --material-themerefneutral-variantneutral-variant98: #f7f9ff;
    --material-themerefneutral-variantneutral-variant99: #fcfcff;
    --material-themerefneutralneutral0: #000000;
    --material-themerefneutralneutral100: #ffffff;
    --material-themerefneutralneutral95: #f0f0f4;
    --material-themerefneutralneutral99: #fcfcff;
    --material-themerefneutralneutralnan: #ffffff;
    --material-themerefprimaryprimary0: #000000;
    --material-themerefprimaryprimary10: #001d32;
    --material-themerefprimaryprimary100: #ffffff;
    --material-themerefprimaryprimary20: #003352;
    --material-themerefprimaryprimary30: #004a75;
    --material-themerefprimaryprimary50: #007dc0;
    --material-themerefprimaryprimary60: #0098e7;
    --material-themerefprimaryprimary80: #95ccff;
    --material-themerefprimaryprimary90: #cde5ff;
    --material-themerefprimaryprimary95: #e8f2ff;
    --material-themerefprimaryprimary98: #f7f9ff;
    --material-themerefprimaryprimary99: #fcfcff;
    --material-themerefsecondarysecondary0: #000000;
    --material-themerefsecondarysecondary100: #ffffff;
    --material-themerefsecondarysecondary20: #233240;
    --material-themerefsecondarysecondary30: #3a4857;
    --material-themerefsecondarysecondary50: #6a7989;
    --material-themerefsecondarysecondary60: #8392a3;
    --material-themerefsecondarysecondary70: #9dadbe;
    --material-themerefsecondarysecondary80: #b9c8da;
    --material-themerefsecondarysecondary90: #d5e4f6;
    --material-themerefsecondarysecondary95: #e8f2ff;
    --material-themerefsecondarysecondary98: #f7f9ff;
    --material-themerefsecondarysecondary99: #fcfcff;
    --material-themereftertiarytertiary0: #000000;
    --material-themereftertiarytertiary100: #ffffff;
    --material-themereftertiarytertiary90: #72fac6;
    --material-themesourceprimary: #1da1f2;
    --material-themesourceseed: #1da1f2;
    --material-themesourcetertiary: #50dcaa;
    --material-themesurfaceslightsurface1: #fcfcff;
    --material-themesurfaceslightsurface2: #fcfcff;
    --material-themesurfaceslightsurface3: #fcfcff;
    --material-themesurfaceslightsurface4: #fcfcff;
    --material-themesurfaceslightsurface5: #fcfcff;
    --material-themesysdarkon-primary: #003352;
    --material-themesysdarkon-primary-container: #cde5ff;
    --material-themesysdarkon-secondary: #233240;
    --material-themesysdarkon-secondary-container: #d5e4f6;
    --material-themesysdarkon-tertiary-container: #72fac6;
    --material-themesysdarkprimary: #95ccff;
    --material-themesysdarkprimary-container: #004a75;
    --material-themesysdarksecondary: #b9c8da;
    --material-themesysdarksecondary-container: #3a4857;
    --material-themesysdarkshadow: #000000;
    --material-themesysdarksurface-tint: #95ccff;
    --material-themesysdarksurface-tint-color: #95ccff;
    --material-themesyslightbackground: #fcfcff;
    --material-themesyslightinverse-on-surface: #f0f0f4;
    --material-themesyslightinverse-primary: #95ccff;
    --material-themesyslighton-error: #ffffff;
    --material-themesyslighton-primary: #ffffff;
    --material-themesyslighton-primary-container: #001d32;
    --material-themesyslighton-secondary: #ffffff;
    --material-themesyslighton-tertiary: #ffffff;
    --material-themesyslightprimary-container: #cde5ff;
    --material-themesyslightsecondary-container: #d5e4f6;
    --material-themesyslightshadow: #000000;
    --material-themesyslightsurface: #fcfcff;
    --material-themesyslightsurface-variant: #dee3eb;
    --material-themesyslighttertiary-container: #72fac6;
    --material-themewhite: #ffffff;
    --manatee: #8c9198;
    --black-coral-pearl: #51606f;
    --solitude: #e8f6fe;
    --white: #ffffff;
    --prussian-blue: rgba(0, 51, 82, 1);
    --ebony-clay: rgba(35, 50, 64, 1);
    --venice-blue: rgba(0, 87, 135, 1);
    --regal-blue: rgba(0, 74, 117, 1);
    --oxford-blue: rgba(58, 72, 87, 1);
    --regent-gray: rgba(131, 146, 163, 1);
    --dodger-blue: rgba(29, 161, 242, 1);

    --font-size-12px: 12px;
    --font-size-xxs: 12px;
    --font-size-22px: 22px;
    --font-size-l: 20px;
    --font-size-m: 18px;
    --font-size-s: 16px;
    --font-size-xl: 24px;
    --font-size-xs: 14px;
    --font-size-xxl: 28px;
    --font-size-xxxl: 36px;

    --font-family-circular_std-bold: 'Circular Std-Bold', Helvetica;
    --font-family-circular_std-medium: 'Circular Std-Medium', Helvetica;
    --font-family-gilroy-regular: 'Gilroy-Regular', Helvetica;
    --font-family-gilroy-semibold: 'Gilroy-Semibold', Helvetica;
    --font-family-sharp_sans-bold: 'Sharp Sans-Bold', Helvetica;
    --font-family-graphik-regular: 'Graphik-Regular', Helvetica;
    --font-family-graphik-semibold: 'Graphik-SemiBold', Helvetica;
    --font-family-sharp_sans-medium: 'Sharp Sans-Medium', Helvetica;
    --font-family-gilroy-medium: 'Gilroy-Medium', Helvetica;
    --font-family-campton-medium: 'Campton-Medium', Helvetica;
    --font-family-campton-thin-medium: 'Campton-Thin-Medium', Helvetica;
    --font-family-campton-semibold: 'Campton-semibold', Helvetica;
    --font-family-campton-book: 'Campton-book', Helvetica;
}
.material-themebody1 {
    font-family: var(--font-family-gilroy-regular);
    font-size: var(--font-size-s);
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.5px;
}

.material-themeoverline {
    font-family: var(--font-family-gilroy-semibold);
    font-size: var(--font-size-12px);
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.5px;
}

.material-themelabellarge {
    font-family: var(--font-family-gilroy-semibold);
    font-size: var(--font-size-xs);
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.1px;
}

.material-themebodymedium {
    font-family: var(--font-family-gilroy-regular);
    font-size: var(--font-size-s);
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.25px;
}

.material-themebodylarge {
    font-family: var(--font-family-gilroy-regular);
    font-size: var(--font-size-m);
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.5px;
}

.material-themeheadlinesmall {
    font-family: var(--font-family-gilroy-regular);
    font-size: var(--font-size-xl);
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0;
}

.material-themetitlemedium {
    font-family: var(--font-family-gilroy-semibold);
    font-size: var(--font-size-s);
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.15px;
}

.material-themetitlelarge {
    font-family: var(--font-family-gilroy-semibold);
    font-size: var(--font-size-22px);
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0;
}

.circularstd-medium-prussian-blue-20px {
    color: var(--material-themerefprimaryprimary20);
    font-family: var(--font-family-circular_std-medium);
    font-size: var(--font-size-l);
    font-style: normal;
    font-weight: 500;
}

.circularstd-bold-prussian-blue-16px {
    color: var(--material-themerefprimaryprimary20);
    font-family: var(--font-family-circular_std-bold);
    font-size: var(--font-size-s);
    font-style: normal;
    font-weight: 700;
}

.circularstd-bold-white-16px {
    color: var(--material-themereferrorerror100);
    font-family: var(--font-family-circular_std-bold);
    font-size: var(--font-size-s);
    font-style: normal;
    font-weight: 700;
}

.circularstd-bold-prussian-blue-36px {
    color: var(--material-themerefprimaryprimary20);
    font-family: var(--font-family-circular_std-bold);
    font-size: var(--font-size-xxxl);
    font-style: normal;
    font-weight: 700;
  }
  
.gilroy-medium-oxford-blue-18px {
    color: var(--material-themerefsecondarysecondary30);
    font-family: var(--font-family-gilroy-medium);
    font-size: var(--font-size-l);
    font-style: normal;
    font-weight: 500;
}

.gilroy-medium-black-coral-pearl-12px-with-padding {
    color: var(--black-coral-pearl);
    font-family: var(--font-family-gilroy-medium);
    font-size: var(--font-size-xs);
    font-style: normal;
    font-weight: 500;
    padding-top: 14px;

  }

.gilroy-medium-black-coral-pearl-12px {
    color: var(--black-coral-pearl);
    font-family: var(--font-family-gilroy-medium);
    font-size: var(--font-size-xs);
    font-style: normal;
    font-weight: 500;
  }

  .gilroy-medium-oxford-blue-16px {
    color: var(--material-themerefsecondarysecondary30);
    font-family: var(--font-family-gilroy-medium);
    font-size: var(--font-size-m);
    font-style: normal;
    font-weight: 500;
  }

  .circularstd-bold-dodger-blue-16px {
    color: var(--material-themekey-colorsprimary);
    font-family: var(--font-family-circular_std-bold);
    font-size: var(--font-size-m);
    font-style: normal;
    font-weight: 700;
  }

  .gilroy-medium-prussian-blue-14px {
    color: var(--material-themerefprimaryprimary20);
    font-family: var(--font-family-gilroy-medium);
    font-size: var(--font-size-s);
    font-style: normal;
    font-weight: 500;
  }

.circularstd-bold-ebony-clay-16px {
    color: var(--material-themerefsecondarysecondary20);
    font-family: var(--font-family-circular_std-bold);
    font-size: var(--font-size-s);
    font-style: normal;
    font-weight: 700;
}

.circularstd-bold-ebony-clay-16px-pricing {
    color: var(--material-themerefsecondarysecondary20);
    font-family: var(--font-family-circular_std-bold);
    font-size: var(--font-size-s);
    font-style: normal;
    font-weight: 700;
}

.circularstd-princing-bold-ebony-clay-16px {
    color: var(--material-themerefsecondarysecondary20);
    font-family: var(--font-family-circular_std-bold);
    font-size: var(--font-size-s);
    font-style: normal;
    font-weight: 700;
}

.circularstd-bold-ebony-clay-16px:hover {
    color: var(--material-themerefsecondarysecondary100);
}

.gilroy-normal-raven-14px {
    color: var(--material-themerefsecondarysecondary50);
    font-family: var(--font-family-gilroy-semibold);
    font-size: var(--font-size-xs);
    font-style: normal;
    font-weight: 400;
}

.circularstd-bold-prussian-blue-28px {
    color: var(--material-themerefprimaryprimary20);
    font-family: var(--font-family-circular_std-bold);
    font-size: var(--font-size-xxl);
    font-style: normal;
    font-weight: 700;
}

.circularstd-medium-dodger-blue-20px {
    color: var(--material-themekey-colorsprimary);
    font-family: var(--font-family-circular_std-medium);
    font-size: var(--font-size-l);
    font-style: normal;
    font-weight: 500;
}

.circularstd-bold-prussian-blue-24px {
    color: var(--material-themerefprimaryprimary20);
    font-family: var(--font-family-circular_std-bold);
    font-size: var(--font-size-xl);
    font-style: normal;
    font-weight: 700;
}

.circularstd-medium-oxford-blue-20px {
    color: var(--material-themerefsecondarysecondary30);
    font-family: var(--font-family-circular_std-medium);
    font-size: var(--font-size-l);
    font-style: normal;
    font-weight: 500;
}

.circularstd-bold-cerulean-16px {
    color: var(--material-themerefprimaryprimary60);
    font-family: var(--font-family-circular_std-bold);
    font-size: var(--font-size-s);
    font-style: normal;
    font-weight: 700;
}

.circularstd-bold-coconut-36px {
    color: var(--material-themerefneutral-variantneutral-variant99);
    font-family: var(--font-family-circular_std-bold);
    font-size: var(--font-size-xxxl);
    font-style: normal;
    font-weight: 700;
}

.circularstd-bold-oxford-blue-28px {
    color: var(--material-themerefsecondarysecondary30);
    font-family: var(--font-family-circular_std-bold);
    font-size: var(--font-size-xxl);
    font-style: normal;
    font-weight: 700;
}

.circularstd-bold-white-28px {
    color: var(--material-themereferrorerror100);
    font-family: var(--font-family-circular_std-bold);
    font-size: var(--font-size-xxl);
    font-style: normal;
    font-weight: 700;
}

.circularstd-medium-prussian-blue-14px {
    color: var(--material-themerefprimaryprimary20);
    font-family: var(--font-family-circular_std-medium);
    font-size: var(--font-size-xs);
    font-style: normal;
    font-weight: 500;
}

.circularstd-bold-prussian-blue-45px {
    color: var(--material-themerefprimaryprimary20);
    font-family: var(--font-family-circular_std-bold);
    font-size: 45px;
    font-style: normal;
    font-weight: 700;
}

.sharpsans-bold-prussian-blue-45px {
    color: var(--prussian-blue);
    font-family: var(--font-family-sharp_sans-bold);
    font-size: 45px;
    font-weight: 700;
    font-style: normal;
}

.graphik-regular-normal-ebony-clay-18px {
    color: var(--ebony-clay);
    font-family: var(--font-family-graphik-regular);
    font-size: var(--font-size-l);
    font-weight: 400;
    font-style: normal;
}

.circularstd-bold-white-36px {
    color: var(--white);
    font-family: var(--font-family-circular_std-bold);
    font-size: 36px;
    font-weight: 700;
    font-style: normal;
}

.graphik-regular-normal-white-18px {
    color: var(--white);
    font-family: var(--font-family-graphik-regular);
    font-size: var(--font-size-l);
    font-weight: 400;
    font-style: normal;
}

.sharpsans-medium-venice-blue-65px {
    color: var(--venice-blue);
    font-family: var(--font-family-sharp_sans-medium);
    font-size: 65px;
    font-weight: 500;
    font-style: normal;
}

.circularstd-bold-regal-blue-24px {
    color: var(--regal-blue);
    font-family: var(--font-family-circular_std-bold);
    font-size: var(--font-size-xxl);
    font-weight: 700;
    font-style: normal;
}

.graphik-regular-normal-oxford-blue-16px {
    color: var(--oxford-blue);
    font-family: var(--font-family-graphik-regular);
    font-size: var(--font-size-m);
    font-weight: 400;
    font-style: normal;
}

.gilroy-medium-oxford-blue-18px {
    color: var(--oxford-blue);
    font-family: var(--font-family-gilroy-medium);
    font-size: var(--font-size-l);
    font-weight: 500;
    font-style: normal;
}

.gilroy-medium-oxford-white-18px {
    color: var(--white);
    font-family: var(--font-family-gilroy-medium);
    font-size: var(--font-size-l);
    font-weight: 500;
    font-style: normal;
}

.gilroy-medium-prussian-blue-16px {
    color: var(--prussian-blue);
    font-family: var(--font-family-gilroy-medium);
    font-size: var(--font-size-m);
    font-weight: 500;
    font-style: normal;
}

.gilroy-semi-bold-manatee-12px {
    color: var(--manatee);
    font-family: var(--font-family-gilroy-semibold);
    font-size: var(--font-size-xxs);
    font-weight: 600;
    font-style: normal;
}

.gilroy-medium-oxford-blue-16px {
    color: var(--oxford-blue);
    font-family: var(--font-family-gilroy-medium);
    font-size: var(--font-size-m);
    font-weight: 500;
    font-style: normal;
}

.gilroy-semi-bold-prussian-blue-16px {
    color: var(--prussian-blue);
    font-family: var(--font-family-gilroy-semibold);
    font-size: var(--font-size-m);
    font-weight: 600;
    font-style: normal;
}

.gilroy-medium-oxford-blue-14px {
    color: var(--oxford-blue);
    font-family: var(--font-family-gilroy-medium);
    font-size: var(--font-size-s);
    font-weight: 500;
    font-style: normal;
}

.gilroy-medium-regent-gray-16px {
    color: var(--regent-gray);
    font-family: var(--font-family-gilroy-medium);
    font-size: var(--font-size-m);
    font-weight: 500;
    font-style: normal;
}

.gilroy-medium-themerefsecondarysecondary60-16px {
    color: var(--themerefsecondarysecondary60);
    font-family: var(--font-family-gilroy-medium);
    font-size: var(--font-size-m);
    font-weight: 500;
    font-style: normal;
}

.cause-card-cause-type-style {
    color: rgba(0, 51, 82, 1.0);
    font-family: var(--font-family-circular_std-medium);
    font-size: var(--font-size-12px);
    font-style: normal;
    font-weight: 500;
}

.bg-blue-app-color {
    background-color: var(--blue-app-color);
}
.bg-white {
    background-color: var(--material-themereferrorerror100);
}

@media only screen and (max-width: 860px) {
    .circularstd-bold-prussian-blue-45px {
        font-size: 35px;
        font-weight: 100;
    }
    .material-themebodylarge {
        font-size: var(--font-size-s);
        font-weight: 200;
    }
    .material-tbody-themebodysmall {
        font-family: var(--font-family-gilroy-regular);
        font-size: var(--font-size-xs);
        font-weight: 400;
        font-style: normal;
        letter-spacing: 0.25px;
        line-height: 20px;
        white-space: wrap;
        color: var(--material-themerefsecondarysecondary30);
    }
    .material-thead-themebodysmall {
        color: var(--material-themerefsecondarysecondary20);
        font-family: var(--font-family-circular_std-bold);
        font-size: var(--font-size-s);
        font-weight: 700;
        font-style: normal;
        height: 20px;
        letter-spacing: 0.1px;
        line-height: 20px;
        white-space: nowrap;
      }

    .material-buttons-themebodysmall {
        color: var(--material-themerefsecondarysecondary20);
        font-family: var(--font-family-circular_std-medium);
        font-size: var(--font-size-xs);
        font-style: normal;
        height: 20px;
        letter-spacing: 0.01px;
        line-height: 20px;
        white-space: nowrap;
    }

    .circularstd-bold-coconut-36px {
        color: var(--material-themerefneutral-variantneutral-variant99);
        font-family: var(--font-family-circular_std-bold);
        font-size: var(--font-size-xxl);
        font-style: normal;
        font-weight: 500;
    }
} 